import { Dispatch } from 'react'
import { sessionToken, localToken } from '../../../authServices/jwt'
import { capitalizingSentence } from '../../helpers'
import { IActionType } from '../../interface/commonInterface'
import {
	getAuthFailed,
	getAuthPending,
	getAuthSuccess,
	getLoginPending,
	getLoginSuccess,
	getLoginFailed,
	getResetTokenPending,
	getResetTokenSuccess,
	getResetTokenFailed,
	resetPasswordPending,
	resetPasswordSuccess,
	resetPasswordFailed,
	getLogoutPending,
	getLogoutSuccess,
	getLogoutFailed,
	refreshTokenPending,
	refreshTokenSuccess,
	refreshTokenFailed,
    confirmResetPasswordCodePending,
    confirmResetPasswordCodeSuccess,
    confirmResetPasswordCodeFailed,
    getResetPasswordCodePending,
    getResetPasswordCodeSuccess,
    getResetPasswordCodeFailed
} from '../action/authAction/authAction'

import {
	getAuthEffect,
	getLoginEffect,
	getResetTokenEffect,
	resetPasswordEffect,
	getLogoutEffect,
	refreshTokenEffect,
    confirmResetPasswordCodeEffect,
    getResetPasswordCodeEffect
} from '../effect/authEffect'

import {
	ILoginEffect,
	IResetPasswordEffect,
	IResetTokenEffect
} from '../effect/interface'

export const getAuth = async (dispatch: Dispatch<IActionType>) => {
	dispatch(getAuthPending())
	const res = await getAuthEffect()
	const notification: string = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		const data = res?.data?.data
		dispatch(getAuthSuccess(data || {}))
		return {
			result: true,
			message: notification || 'Get Info Successfully'
		}
	}
	dispatch(getAuthFailed(notification))
	return {
		result: false,
		message: notification || 'Cannot Get Authentication'
	}
}

export const getLogin = async (dispatch: Dispatch<IActionType>, data: ILoginEffect) => {
	dispatch(getLoginPending())
	const res = await getLoginEffect(data)
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		const token = res.data.data.token
		sessionToken.setToken(token)
		dispatch(getLoginSuccess(token || ''))
		return {
			result: true,
			message: notification || 'Login Successful'
		}
	}
	dispatch(getLoginFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Something Went Wrong'
	}
}

export const getResetToken = async (dispatch: Dispatch<IActionType>, data: IResetTokenEffect) => {
	dispatch(getResetTokenPending())
	const res = await getResetTokenEffect(data)
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		const resetToken = res?.data?.data
		dispatch(getResetTokenSuccess(resetToken || ''))
		return {
			result: true,
			message: notification || 'Get Reset Token Successfully'
		}
	}
	dispatch(getResetTokenFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Cannot Get Reset Token'
	}
}

export const resetPassword = async (dispatch: Dispatch<IActionType>, data: IResetPasswordEffect) => {
	dispatch(resetPasswordPending())
	const res = await resetPasswordEffect(data)
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		dispatch(resetPasswordSuccess())
		return {
			result: true,
			message: notification || 'Update Password Successfully'
		}
	}
	dispatch(resetPasswordFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Cannot Reset Password'
	}
}

export const getLogout = async (dispatch: Dispatch<IActionType>) => {
	dispatch(getLogoutPending())
	const res = await getLogoutEffect()
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		localToken.clearToken()
		sessionToken.clearToken()
		dispatch(getLogoutSuccess())
		return {
			result: true,
			message: notification || 'Sign Out Successfully'
		}
	}
	dispatch(getLogoutFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Sign Out Failed'
	}
}

export const refreshToken = async (dispatch: Dispatch<IActionType>) => {
	dispatch(refreshTokenPending())
	const res = await refreshTokenEffect()
	const notification: string = capitalizingSentence(res?.data?.message)
	if (res && res.status >= 200 && res.status <= 300) {
		const token = res.data?.data
		sessionToken.setToken(token)
		dispatch(refreshTokenSuccess(token))
		return {
			result: true,
			message: notification || 'Refresh Token'
		}

	}
	sessionToken.clearToken()
	dispatch(refreshTokenFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Something Went Wrong'
	}
}

export const getResetPasswordCode = async (
    dispatch: Dispatch<IActionType>,
    email: string
) => {
    dispatch(getResetPasswordCodePending());
    const res = await getResetPasswordCodeEffect(email);
    const notification: string = capitalizingSentence(res?.data?.message);
    if (res && res.status >= 200 && res.status <= 300) {
        dispatch(getResetPasswordCodeSuccess());
        return {
            result: true,
            message: notification || "Please check your email",
        };
    }
    dispatch(
        getResetPasswordCodeFailed(notification || "Email Does Not Exists")
    );
    return {
        result: false,
        message: notification || "Email Does Not Exists",
    };
};

export const confirmResetPasswordCode = async (
    dispatch: Dispatch<IActionType>,
    token: string
) => {
    dispatch(confirmResetPasswordCodePending());
    const res = await confirmResetPasswordCodeEffect(token);
	localStorage.setItem('verify_code', token);
    const notification: string = capitalizingSentence(res?.data?.message);
    if (res && res.status >= 200 && res.status <= 300 && res.data?.data) {
        dispatch(confirmResetPasswordCodeSuccess(token));
        return {
            result: true,
            message: notification || "",
        };
    }
    dispatch(confirmResetPasswordCodeFailed(notification || "Wrong verify code"));
    return {
        result: false,
        message: notification || "Wrong verify code",
    };
};