const tokenKey = "ACCESS_TOKEN";

const localToken: localTokenInterface = {
	getToken() {
		return window.localStorage.getItem(tokenKey) || "";
	},
	setToken(token) {
		window.localStorage.setItem(tokenKey, token);
	},
	clearToken() {
		window.localStorage.removeItem(tokenKey);
	},
};

const sessionToken: sessionTokenInterface = {
	getToken() {
		return window.localStorage.getItem(tokenKey) || "";
	},
	setToken(token) {
		window.localStorage.setItem(tokenKey, token);
	},
	clearToken() {
		window.localStorage.removeItem(tokenKey);
	},
};

interface localTokenInterface {
	getToken(): string;

	setToken(token: string): void;

	clearToken(): void;
}

interface sessionTokenInterface {
	getToken(): string;

	setToken(token: string): void;

	clearToken(): void;      
}  

export { localToken, sessionToken };
