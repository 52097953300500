import { AxiosRequestConfig } from "axios";
import { axiosInstance, axiosInstanceLogin } from "../../../configs/axiosConfig";
import { ILoginEffect, IResetPasswordEffect, IResetTokenEffect } from "./interface";

const configs: AxiosRequestConfig = {
	headers: {
		"Content-Type": "application/json",
	},
};

export const getAuthEffect = async () => {
	try {
		return await axiosInstance.get("/auth/me");
	} catch (error) {
		return error;
	}
};

export const getLoginEffect = async (data: ILoginEffect) => {
	try {
		return await axiosInstanceLogin.post("/auth/login", data, configs);
	} catch (error) {
		return error;
	}
};

export const getResetTokenEffect = async (data: IResetTokenEffect) => {
	try {
		return await axiosInstance.post("/auth/forgot-password/send-code", data, configs);
	} catch (error) {
		return error;
	}
};

export const resetPasswordEffect = async (data: IResetPasswordEffect) => {
	try {
		return await axiosInstance.post("/auth/forgot-password/new-password", data, configs);
	} catch (error) {
		return error;
	}
};

export const getLogoutEffect = async () => {
	try {
		return await axiosInstance.post("/auth/logout", {}, configs);
	} catch (error) {
		return error;
	}
};

export const refreshTokenEffect = async () => {
	try {
		return await axiosInstance.post("/auth/refresh-token", {}, configs);
	} catch (error) {
		return error;
	}
};

export const getResetPasswordCodeEffect = async (email: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/send-code", {email});
    } catch (error) {
        return error;
    }
};

export const confirmResetPasswordCodeEffect = async (token: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/confirm-code", {token});
    } catch (error) {
        return error;
    }
};